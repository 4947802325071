import { useEffect, useState } from 'react';
import router from 'next/router';

export function useMiddleware(): string[] {
  const [middleware, setMiddleware] = useState(['']);

  useEffect(() => {
    if (!router.router?.route) {
      return;
    }
    const middlewares = [
      {
        active: (route: string) =>
          route.startsWith('/account/email-confirm') ||
          route.startsWith('/account/confirm') ||
          route.startsWith('/public-profile'),
        middleware: [],
      },
      {
        active: (route: string) => route.startsWith('/account/preregister-confirm'),
        middleware: [],
      },
      {
        active: (route: string) => route.startsWith('/account/newsletter-signup-confirm'),
        middleware: [],
      },
      {
        active: (route: string) => route.startsWith('/account/unsubscribe/confirm'),
        middleware: [],
      },
      {
        active: (route: string) => route.startsWith('/admin'),
        middleware: ['user.loggedId'],
      },
      {
        active: (route: string) => route.startsWith('/account') && !route.startsWith('/account/upgrade'),
        middleware: ['user.loggedId'],
      },
      {
        active: (route: string) => route.startsWith('/abenity'),
        middleware: ['user.loggedId', 'user.verified', 'user.vrMember'],
      },
    ];
    setMiddleware(
      middlewares.find(({ active }) => active(router.router!.route))?.middleware ?? ['user.loggedId', 'user.verified']
    );
  }, [router]);

  return middleware;
}
